.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.mb-1 {
  height: 100px;
}
.topname {
  margin: 9px 0 0 0;
}
h4.secondname {
  margin-top: -10px;
}

.ant-progress-text {
  visibility: hidden;
}

.thumbs-wrapper.axis-vertical {
  display: none;
}
.btndeivce {
  clear: both;
  float: right;
}
thead.ant-table-thead {
  background-color: rgba(62, 121, 247, 0.1);
}

#map,
#mapUI {
  width: inherit;
  height: inherit;
  background-color: #000;
}
.filterSubwayLines {
  position: absolute;
  top: 10px;
  z-index: 10;
  width: 200px;
  height: auto;
  background: hsla(0, 100%, 100%, 0.8);
  margin: 10px;
  font-family: sans-serif;
  font-size: 13px;
  padding: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.filterSubwayLines hr {
  border: 1px solid #000;
  margin: 5px;
}
.filterSubwayLines h3 {
  margin-top: 0;
  padding-left: 5px;
}
.filterSubwayLines p {
  padding-left: 5px;
}
.filterSubwayLines select {
  display: block;
  margin: 0 auto;
}

.heatmap {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.pitch {
  border: 1px solid black;
}
